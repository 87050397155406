var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.dialog_Vinculacion_item)?_c('v-row',{attrs:{"justify":"center"}},[_c('v-dialog',{attrs:{"scrollable":"","max-width":"60%"},model:{value:(_vm.dialog_Vinculacion_flag),callback:function ($$v) {_vm.dialog_Vinculacion_flag=$$v},expression:"dialog_Vinculacion_flag"}},[_c('v-card',[_c('v-card-title',[_vm._v("Vinculación de dispositivo para usuario ("+_vm._s(_vm.dialog_Vinculacion_item.alias)+")")]),_c('v-divider'),_c('v-card-title',[_vm._v("Dispositivo actual")]),_c('v-card-text',[_c('v-row',[_c('v-col',[(_vm.dialog_Vinculacion_item.id_dispositivo)?_c('p',[_vm._v(" Marca: "),_c('strong',[_vm._v(_vm._s(_vm.dialog_Vinculacion_item.marca))]),_c('br'),_vm._v(" Modelo: "),_c('strong',[_vm._v(_vm._s(_vm.dialog_Vinculacion_item.modelo))])]):_vm._e(),(_vm.dialog_Vinculacion_item.id_dispositivo)?_c('p',[_vm._v(" Dispositivo: "),_c('strong',{staticClass:"mb-2"},[_vm._v(_vm._s(_vm.dialog_Vinculacion_item.id_dispositivo))]),_c('br'),_vm._v(" Fecha de vinculación: "),_c('strong',[_vm._v(_vm._s(_vm.getDate(_vm.dialog_Vinculacion_item.fecha_vinculacion)))])]):_vm._e(),(!_vm.dialog_Vinculacion_item.id_dispositivo)?_c('p',[_vm._v(" Este usuario no tiene un dispositivo vinculado. ")]):_vm._e(),(!_vm.dialog_Vinculacion_item.id_dispositivo)?_c('p',[_vm._v(" inicie sesión con las credenciales de este usuario ("),_c('strong',[_vm._v(_vm._s(_vm.dialog_Vinculacion_item.alias)+" ")]),_vm._v(") para vincularlo a un dispositivo ")]):_vm._e()]),_c('v-col',{staticClass:"d-inline-flex justify-center"},[_c('v-row',[_c('v-col',{staticClass:"d-inline-flex justify-center",attrs:{"cols":"12"}},[_c('v-btn',{attrs:{"disabled":_vm.dialog_Vinculacion_item.id_dispositivo == null
                      ? true
                      : false,"small":"","elevation":"2","color":"red","dark":_vm.dialog_Vinculacion_item.id_dispositivo == null
                      ? false
                      : true},on:{"click":function($event){return _vm.fn_checkDesvincularDispositivo()}}},[_vm._v("Desvincular dispositivo")])],1),_c('v-col',{attrs:{"cols":"12"}},[_c('p',{staticClass:"text-center text--disabled"},[_vm._v(" Desvincular el dispositivo permite al usuario iniciar sesión en otro dispositivo ")]),_c('v-spacer'),(
                    _vm.dialog_Vinculacion_item.id_dispositivo == null
                      ? false
                      : true
                  )?_c('p',{staticClass:"text-center text--disabled"},[_vm._v(" Esta acción no se puede revertir ")]):_vm._e()],1)],1)],1)],1)],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"float-right",attrs:{"color":"green","dark":""},on:{"click":function($event){return _vm.$emit('fndialogEdit', false)}}},[_vm._v(" Cerrar ")])],1)],1)],1),_c('v-dialog',{attrs:{"persistent":"","max-width":"550px"},model:{value:(_vm.dialog2),callback:function ($$v) {_vm.dialog2=$$v},expression:"dialog2"}},[_c('v-card',[_c('v-card-text',{staticClass:"mt-3"},[_c('v-row',[_c('v-col',{staticClass:"d-inline-flex justify-center",attrs:{"cols":"12"}},[_c('v-card-title',[_vm._v(" ¿Está seguro de querer desvincular el dispositivo? ")])],1),_c('v-col',{staticClass:"d-inline-flex justify-center"},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"green","dark":""},on:{"click":function($event){return _vm.fn_DesvincularDispositivo(_vm.dialog_Vinculacion_item.id)}}},[_vm._v(" Si, desvincular ")])],1),_c('v-col',{staticClass:"d-inline-flex justify-left"},[_c('v-btn',{attrs:{"color":"red","dark":""},on:{"click":function($event){_vm.dialog2 = !_vm.dialog2}}},[_vm._v(" Cancelar ")]),_c('v-spacer')],1)],1)],1)],1)],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }