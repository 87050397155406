<template>
  <v-row justify="center" v-if="dialog_Vinculacion_item">
    <v-dialog v-model="dialog_Vinculacion_flag" scrollable max-width="60%">
      <v-card>
        <v-card-title
          >Vinculación de dispositivo para usuario ({{
            dialog_Vinculacion_item.alias
          }})</v-card-title
        >
        <v-divider></v-divider>
        <v-card-title>Dispositivo actual</v-card-title>
        <v-card-text>
          <v-row>
            <v-col>
              <p v-if="dialog_Vinculacion_item.id_dispositivo">
                Marca: <strong>{{ dialog_Vinculacion_item.marca }}</strong
                ><br />
                Modelo: <strong>{{ dialog_Vinculacion_item.modelo }}</strong>
              </p>

              <p v-if="dialog_Vinculacion_item.id_dispositivo">
                Dispositivo:
                <strong class="mb-2">{{
                  dialog_Vinculacion_item.id_dispositivo
                }}</strong>
                <br />
                Fecha de vinculación:
                <strong>{{
                  getDate(dialog_Vinculacion_item.fecha_vinculacion)
                }}</strong>
              </p>

              <p v-if="!dialog_Vinculacion_item.id_dispositivo">
                Este usuario no tiene un dispositivo vinculado.
              </p>
              <p v-if="!dialog_Vinculacion_item.id_dispositivo">
                inicie sesión con las credenciales de este usuario (<strong
                  >{{ dialog_Vinculacion_item.alias }} </strong
                >) para vincularlo a un dispositivo
              </p> </v-col
            ><v-col class="d-inline-flex justify-center">
              <v-row>
                <v-col cols="12" class="d-inline-flex justify-center"
                  ><v-btn
                    :disabled="
                      dialog_Vinculacion_item.id_dispositivo == null
                        ? true
                        : false
                    "
                    @click="fn_checkDesvincularDispositivo()"
                    small
                    elevation="2"
                    color="red"
                    :dark="
                      dialog_Vinculacion_item.id_dispositivo == null
                        ? false
                        : true
                    "
                    >Desvincular dispositivo</v-btn
                  ></v-col
                >
                <v-col cols="12" class=""
                  ><p class="text-center text--disabled">
                    Desvincular el dispositivo permite al usuario iniciar sesión
                    en otro dispositivo
                  </p>
                  <v-spacer></v-spacer>
                  <p
                    v-if="
                      dialog_Vinculacion_item.id_dispositivo == null
                        ? false
                        : true
                    "
                    class="text-center text--disabled"
                  >
                    Esta acción no se puede revertir
                  </p></v-col
                >
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green"
            dark
            class="float-right"
            @click="$emit('fndialogEdit', false)"
          >
            Cerrar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialog2" persistent max-width="550px">
      <v-card>
        <v-card-text class="mt-3">
          <v-row>
            <v-col cols="12" class="d-inline-flex justify-center">
              <v-card-title>
                ¿Está seguro de querer desvincular el dispositivo?
              </v-card-title>
            </v-col>
            <v-col class="d-inline-flex justify-center">
              <v-spacer></v-spacer>
              <v-btn
                text
                color="green"
                dark
                @click="fn_DesvincularDispositivo(dialog_Vinculacion_item.id)"
              >
                Si, desvincular
              </v-btn>
            </v-col>
            <v-col class="d-inline-flex justify-left">
              <v-btn color="red" dark @click="dialog2 = !dialog2">
                Cancelar
              </v-btn>
              <v-spacer></v-spacer>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import moment from 'moment';
import { mapActions } from 'vuex';
export default {
  data() {
    return {
      dialogm1: '',
      dialog: false,
      dialog2: false,
    };
  },
  props: {
    dialog_Vinculacion_item: {
      type: Object,
    },
    dialog_Vinculacion_flag: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    ...mapActions('Usuarios', {
      findUsuarios: 'find',
      patchUsuario: 'patch',
    }),
    getDate(date) {
      return date ? moment(date).utc().format('DD MMMM YYYY hh:mm a') : '';
    },
    fn_checkDesvincularDispositivo() {
      if (this.dialog_Vinculacion_item) {
        this.dialog2 = true;
      }
    },
    fn_DesvincularDispositivo(id) {
      this.patchUsuario([
        id,
        { marca: null, modelo: null, id_dispositivo: null, reg_token: null },
        {},
      ]).then(() => {
        this.$emit('fndialogEdit', false);
        this.dialog2 = false;
        this.$notify({
          type: 'success',
          group: 'foo',
          title: `Dispositivo desvinculado`,
          text: `El usuario puede ahora vincular un nuevo dispositivo`,
        });
      });
    },
  },
  mounted() {},
};
</script>
