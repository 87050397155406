<template>
  <v-container fluid>
    <v-row align="center">
      <v-col>
        <v-text-field
          v-model="search_supervisora"
          title="Usuario"
          label="Alias, nombre, sector"
          class=""
          append-icon="mdi-account"
          v-on:keyup.enter="(page = 1), fnFindUsuarios()"
        ></v-text-field>
      </v-col>

      <v-col>
        <v-text-field
          v-model="searchdivision"
          label="División"
          class=""
          append-icon="mdi-magnify"
          v-on:keyup.enter="(page = 1), fnFindUsuarios()"
        ></v-text-field>
      </v-col>
      <v-col>
        <v-text-field
          v-model="searchregion"
          label="Región"
          class=""
          append-icon="mdi-magnify"
          v-on:keyup.enter="(page = 1), fnFindUsuarios()"
        ></v-text-field>
      </v-col>
      <v-col>
        <v-text-field
          v-model="searchtipo"
          label="Agente de comercio"
          class=""
          append-icon="mdi-magnify"
          v-on:keyup.enter="(page = 1), fnFindUsuarios()"
        ></v-text-field>
      </v-col>

      <v-col>
        <v-btn
          @click="(page = 1), fnFindUsuarios()"
          small
          color="blue-grey"
          class="white--text"
        >
          Buscar
          <v-icon right dark> mdi-magnify </v-icon>
        </v-btn>
      </v-col>
      <v-col cols="12">
        <v-data-table
          disable-pagination
          :options.sync="options"
          :hide-default-footer="true"
          :headers="headers"
          :items="items"
          class="elevation-1"
          :item-key="items.id"
          loading="true"
        >
          <template slot="item.alias" slot-scope="props">
            <span class="d-flex-inline">
              {{ props.item.alias }}
              <v-chip
                x-small
                class=""
                :title="'Ultima actualización ' + getDate(props.item.updatedAt)"
                :color="props.item.estado == 'De Baja' ? '' : 'green lighten-4'"
              >
                {{ props.item.estado }}
              </v-chip></span
            >
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <!-- <v-icon
              small
              class="align-center"
              color="primary"
              @click="fnShowPw(item.id + 'pwfield', item.gestion_code)"
              title="Ver la contraseña del supervisora"
              >mdi-eye</v-icon
            > -->
            <v-icon
              :disabled="item.estado == 'De Baja'"
              class="mr-2"
              text
              @click="
                fnEditPw(item.id, 'editPwMode'),
                  (editSupName = item.nombre_completo)
              "
              small
              color=""
              title="Editar la contraseña de supervisora"
              >mdi-pencil</v-icon
            >
            <v-icon
              small
              :disabled="fnDetectIfCanChangeModulos(item)"
              class="align-center"
              color="primary"
              @click="fnOpenDialog_EditarUsuario(item.id)"
              title="Gestión de modulos"
            >
              mdi-format-list-bulleted
            </v-icon>

            <v-icon
              small
              class="align-center ml-1"
              color="primary"
              @click="fn_openDialog_vinculaciondeDispositivo(item)"
              title="Vinculación de dispositivo"
            >
              mdi-cellphone-cog
            </v-icon>

            <v-icon
              small
              class="align-center ml-1"
              color="primary"
              @click="fn_openDialog_logDeActualizaciones(item)"
              title="Historial de cambios de contraseña"
            >
              mdi-math-log
            </v-icon>

            <!-- <v-icon small @click="dialogDelete = true"> mdi-delete </v-icon> -->
          </template>
          <template v-slot:[`header.codigo`]="{ header }">
            {{ header.text.toUpperCase() }}
          </template>
          <template slot="item.gps_flag" slot-scope="props">
            <span
              v-if="props.item.gps_flag == 0"
              :title="getDate(props.item.gps_fecha)"
              class="red--text"
              >Apagado</span
            >
            <span
              v-if="props.item.gps_flag == 1"
              :title="getDate(props.item.gps_fecha)"
              class="green--text"
              >Encendido</span
            >
          </template>
          <template slot="item.total_consejeras" slot-scope="props">
            <span>{{ props.item.total_consejeras }} </span>
          </template>
          <template slot="item.bateria" slot-scope="props">
            <span
              v-if="props.item.bateria != null"
              :title="getDate(props.item.bateria_fecha)"
              >{{ props.item.bateria }} %</span
            >
            <span v-else title="Sin información disponible"> N/A</span>
          </template>
          <template slot="item.contrasena" slot-scope="props">
            <v-text-field
              name="name"
              :id="props.item.id + 'pwfield'"
              type="password"
              :value="props.item.gestion_code"
              disabled
            ></v-text-field>
          </template> </v-data-table
      ></v-col>
      <v-col cols="12" sm="12" class="d-flex justify-end">
        <span class="mt-3">Total de registros: {{ totalRecords }}</span>
        <v-pagination
          class="float-right"
          v-model="page"
          :length="totalPages"
          total-visible="7"
          next-icon="mdi-menu-right"
          prev-icon="mdi-menu-left"
          @input="handlePageChange"
        ></v-pagination>
      </v-col>
    </v-row>
    <!-- agregar modulos a usuario de tipo "agente de comercio" -->
    <dialogEditarModulosSupervisoras
      :listadoDeModulos_ModulosRestringidos="true"
      id="dialogEditarUsuario"
      :flag_editar_usuario="flag_editar_usuario"
      :dialogEdit="dialogEdit"
      @fndialogEdit="(dialogEdit = false), (flag_editar_usuario = null)"
    />
    <!-- fin agregar modulos a usuario de tipo "agente de comercio" -->
    <!-- agregar vinculacion de dispositivo" -->
    <usuarioVinculacionDeDispositivo
      :dialog_Vinculacion_flag="dialog_Vinculacion_flag"
      :dialog_Vinculacion_item="dialog_Vinculacion_item"
      @fndialogEdit="
        (dialog_Vinculacion_flag = false), (dialog_Vinculacion_item = null)
      "
    />
    <!-- fin agregar vinculacion de dispositivo" -->

    <!-- dialog de confirmación para desvinculación de dispositivo -->
    <v-dialog
      v-model="dialog"
      transition="dialog-bottom-transition"
      max-width="600"
    >
      <v-card>
        <v-toolbar v-if="!dialog_edit" color="primary" dark
          >Confirma tu usuario para poder editar la contraseña</v-toolbar
        >
        <v-toolbar v-if="dialog_edit" color="primary" dark
          >Modifica la contraseña de la supervisora {{ editSupName }}</v-toolbar
        >
        <v-card-text>
          <!-- form validar  usuario , y editar credenciales -->
          <v-text-field
            v-model="dialogUsuarioModel"
            label="Usuario"
            class="mt-2"
            append-icon="mdi-account"
            @keydown="$event.preventDefault()"
            v-on:keyup.enter="(page = 1), fnFindUsuarios()"
          ></v-text-field>
          <v-text-field
            v-if="!dialog_edit"
            class="mt-2"
            v-model="varValidatePassword"
            label="Escribe tu contraseña"
            required
            type="password"
            v-on:keyup.enter="fnValidarCredenciales()"
          ></v-text-field>
          <v-card-actions v-if="!dialog_edit">
            <v-spacer></v-spacer>
            <v-btn
              color=""
              class="float-right"
              @click="dialog = false"
              :title="'Puedes modificar la contraseña de la supervisora'"
            >
              Cancelar
            </v-btn>
            <v-btn
              color="yellow"
              class="float-right"
              @click="fnValidarCredenciales"
              :title="'Valida tu usuario para poder visualizar o editar la contraseña'"
            >
              VALIDA TU CONTRASEÑA
            </v-btn>
          </v-card-actions>

          <v-text-field
            v-if="dialog_edit"
            class="mt-2"
            v-model="varNewPassword"
            label="Nueva contraseña"
            required
            type="password"
            v-on:keyup.enter="fnPatchSupervisoraPw()"
            @keydown="$event.keyCode === 32 ? $event.preventDefault() : false"
          ></v-text-field>
        </v-card-text>

        <v-card-actions v-if="dialog_edit">
          <v-spacer></v-spacer>
          <v-btn
            color=""
            class="float-right"
            @click="dialog = false"
            :title="'Puedes modificar la contraseña de la supervisora'"
          >
            Cancelar
          </v-btn>
          <v-btn
            color="green"
            dark
            class="float-right"
            @click="fnPatchSupervisoraPw"
            :title="'Puedes modificar la contraseña de la supervisora'"
          >
            Editar contraseña
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- fin dialog de confirmación para desvinculación de dispositivo -->
    <!-- agregar vinculacion de dispositivo" -->
    <usuarioHistorialCambiosContrasenia
      :dialog_log_flag="dialog_log_flag"
      :dialog_log_item="dialog_log_item"
      @fndialogEdit="(dialog_log_flag = false), (dialog_log_item = null)"
    />
    <!-- fin agregar vinculacion de dispositivo" -->
  </v-container>
</template>
<script>
import moment from 'moment';
require('moment/locale/es');
import { mapActions } from 'vuex';
import dialogEditarModulosSupervisoras from './dialog_editar_modulos_supervisoras.vue';
import usuarioVinculacionDeDispositivo from './dialog_SupervisoraVinculacionDispositivo.vue';
import usuarioHistorialCambiosContrasenia from './dialog_usuarioHistorialCambiosContrasenia.vue';
export default {
  components: {
    dialogEditarModulosSupervisoras,
    usuarioVinculacionDeDispositivo,
    usuarioHistorialCambiosContrasenia,
  },
  computed: {
    dialogUsuarioModel() {
      if (this.dialog_edit == false) {
        return this.$store.state.auth.payload.usuario.alias;
      } else return this.editSupName;
    },
  },
  data() {
    return {
      // historial cambios de contraseña
      dialog_log_item: null,
      dialog_log_flag: false,
      //vinculación de dispositivos moviles a usuarios
      dialog_Vinculacion_item: null,
      dialog_Vinculacion_flag: false,
      //agregar modulos a usuario de tipo "agente de comercio"
      flag_editar_usuario: null,
      dialogEdit: false,
      //
      totalRecords: 0,
      //
      searchSupervisora: '',
      search_supervisora: '',
      searchtipo: '',
      searchregion: '',
      searchdivision: '',
      editSupName: '',
      varNewPassword: '',
      varValidatePassword: '',
      flag_autenticated: false,
      flag_last_seen_pw_input_id: '',
      flag_last_seen_user_id: '',
      dialog: false,
      dialog_edit: false,
      /** */
      options: {},
      page: 1,
      totalPages: 0,
      pageSize: 5,
      pageSizes: [5, 15, 50],
      /** */
      items: [],
      headers: [
        {
          text: 'Usuario',
          align: 'start',
          sortable: false,
          value: 'alias',
          title: 'Credenciales para inicio de sesión',
          width: '115px',
        },
        {
          text: 'Sector',
          align: 'start',
          sortable: false,
          value: 'codigo',
        },
        { text: 'División', value: 'division', width: '103px' },
        { text: 'Región', value: 'region' },

        { text: 'Agente de comercio', value: 'tipo' },
        { text: 'NOMBRE', value: 'nombre_completo' },
        { text: 'CONSEJERAS', value: 'total_consejeras' },
        { text: 'GPS', align: 'start', sortable: false, value: 'gps_flag' },
        { text: 'BATERÍA', align: 'start', sortable: false, value: 'bateria' },
        // prettier-ignore
        // { text: 'CONTRASEÑA', value: 'contrasena' },
        { text: '', value: 'edit' },
        { text: 'Configuraciones', value: 'actions', align: 'center' },
      ],
    };
  },
  watch: {
    search_supervisora(newValue) {
      if (newValue == '') {
        this.fnFindUsuarios();
      }
    },
    flag_autenticated(newValue) {
      if (newValue == true && this.flag_last_seen_pw_input_id != 'editPwMode') {
        this.fnShowPw(
          this.flag_last_seen_pw_input_id,
          this.flag_last_seen_user_id
        );
      }
    },
    options: {
      handler() {
        this.fnFindUsuarios();
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions('Usuarios', {
      getUsuarios: 'get',
      findUsuarios: 'find',
      patchUsuario: 'patch',
    }),
    ...mapActions('DecryptPw', {
      findDecryptPw: 'find',
    }),
    getDate(date) {
      return date ? moment(date).utc().format('DD MMMM YYYY hh:mm a') : '';
    },
    getParams() {
      const params = {};
      params['sesion_rol'] = 2;
      params['$limit'] = this.pageSize;
      params['$skip'] = (this.page - 1) * this.pageSize;
      if (
        this.options.sortBy &&
        this.options.sortBy.length === 1 &&
        this.options.sortDesc.length === 1
      ) {
        let sorting = {};
        let flag = this.options.sortDesc[0] ? 1 : -1;
        sorting[this.options.sortBy[0].toString()] = flag;
        params['$sort'] = sorting;
      }
      params['region'] = { $ne: '' };
      if (this.search_supervisora != '') {
        params['$or'] = [
          {
            alias: { $like: '%' + this.search_supervisora + '%' },
          },
          {
            codigo: { $like: '%' + this.search_supervisora + '%' },
          },
          {
            nombre_completo: { $like: '%' + this.search_supervisora + '%' },
          },
        ];
      }

      if (this.searchtipo != '') {
        params['tipo'] = { $like: '%' + this.searchtipo + '%' };
      }

      if (this.searchregion != '') {
        params['region'] = { $like: '%' + this.searchregion + '%' };
      }

      if (this.searchdivision != '') {
        params['division'] = { $like: '%' + this.searchdivision + '%' };
      }

      return params;
    },
    fnFindUsuarios() {
      this.findUsuarios({ query: this.getParams() }).then((result) => {
        this.items = result.data;
        this.totalRecords = result.total;
        this.totalPages = Math.ceil(
          parseInt(result.total) / parseInt(this.pageSize)
        );
      });
    },
    handlePageChange(value) {
      this.page = value;
      this.fnFindUsuarios();
    },
    fnValidarCredenciales() {
      this.$store
        .dispatch('auth/authenticate', {
          strategy: 'local',
          alias: this.$store.state.auth.payload.usuario.alias,
          password: this.varValidatePassword,
        })
        .then(() => {
          //
          this.flag_autenticated = true;
          if (this.flag_last_seen_pw_input_id != 'editPwMode') {
            this.dialog = false;
          } else {
            this.dialog_edit = true;
          }

          //
          this.$notify({
            type: 'success',
            group: 'foo',
            title: `Usuario confirmado`,
            text: ``,
          });
        })
        .catch(() => {
          this.flag_autenticated = false;

          this.$notify({
            type: 'error',
            group: 'foo',
            title: `ERROR`,
            text: `Tu usuario y contraseña no concuerdan, por favor intentalo de nuevo`,
          });
        });
    },
    /** */
    fnShowPw(idPwField, id) {
      if (this.flag_autenticated == false) {
        this.dialog = true;
        this.flag_last_seen_pw_input_id = idPwField;
        this.flag_last_seen_user_id = id;
      } else {
        this.findDecryptPw({
          query: {
            gestion_crypited_code: id,
          },
        }).then((result) => {
          document.getElementById(idPwField).value =
            result.data[0].gestion_code;
        });
        if (document.getElementById(idPwField).type == 'text') {
          document.getElementById(idPwField).type = 'password';
        } else {
          document.getElementById(idPwField).type = 'text';
        }
      }
    },
    fnEditPw(id, mode) {
      // si usuario admin no autenticado
      //mostrar dialog para validacion de contraseña
      //caputrar ID de ultima supervisora
      this.flag_last_seen_pw_input_id = mode;
      this.flag_last_seen_user_id = id;
      if (this.flag_autenticated == false) {
        this.dialog = true;
      }
      // si usuario admimn autenticado
      //mostrar dialog para validacion de contraseña
      //hailita edición de contraseña de usuario
      if (this.flag_autenticated == true) {
        this.dialog = true;
        this.dialog_edit = true;
      }
    },
    fnPatchSupervisoraPw() {
      this.$store.state.globalLoading = true;
      if (this.varNewPassword == '' || this.varNewPassword == null) {
        this.$store.state.globalLoading = false;
        this.$notify({
          type: 'error',
          group: 'foo',
          title: 'La contraseña no puede ir vacía',
          text: 'No se ha podido editado las credenciales del usuario',
        });

        return;
      }

      if (this.varNewPassword.length <= 4) {
        this.$store.state.globalLoading = false;
        this.$notify({
          type: 'error',
          group: 'foo',
          title: 'La contraseña no puede ser menor a 5 caracteres',
          text: 'No se ha podido editado las credenciales del usuario',
        });

        return;
      }
      let newCreds = {
        password: this.varNewPassword,
        gestion_code: this.varNewPassword,
      };
      /** crear usuairo */
      this.patchUsuario([this.flag_last_seen_user_id, newCreds, {}])
        .then(() => {
          this.$store.state.globalLoading = false;
          this.dialog = false;
          console.log('flag_last_seen_user_id ' + this.flag_last_seen_user_id);
          /** */
          // document.getElementById(
          //   this.flag_last_seen_user_id + 'pwfield'
          // ).value = this.varNewPassword;
          /** */
          this.varNewPassword = '';
          this.varValidatePassword = '';
          this.$notify({
            type: 'success',
            group: 'foo',
            title: 'Usuario editado',
            text: 'Se ha editado el usuario',
          });
        })
        .catch((e) => {
          console.log(e);
          this.$store.state.globalLoading = false;
          this.$notify({
            type: 'error',
            group: 'foo',
            title: 'Error al crear usuario',
            text: e.message,
          });
        });
    },
    fnFindIndex(itemId) {
      let index = this.items
        .map(function (x) {
          return x.id;
        })
        .indexOf(itemId);

      return index;
    },
    /** agregar modulos a usuario de tipo "agente de comercio" */
    fnOpenDialog_EditarUsuario(idUsuario) {
      this.dialogEdit = true;
      this.flag_editar_usuario = idUsuario;
    },
    /**  */
    /** fnMostrar o no opciones de configuracion segun usuario */
    fnDetectIfCanChangeModulos(item) {
      if (item.estado == 'Activa' && item.tipo != 'Supervisoras') {
        return false;
      } else {
        return true;
      }
    },
    /** agregar modulos a usuario de tipo "agente de comercio" */
    async fn_openDialog_vinculaciondeDispositivo(usuario) {
      this.dialog_Vinculacion_flag = true;
      console.log();
      this.dialog_Vinculacion_item = await this.getUsuarios(usuario.id);
      console.log(this.dialog_Vinculacion_item);
    },
    /**  */
    /** agregar modulos a usuario de tipo "agente de comercio" */
    async fn_openDialog_logDeActualizaciones(usuario) {
      this.dialog_log_flag = true;
      this.dialog_log_item = await this.getUsuarios(usuario.id);
    },
  },
  mounted() {
    this.fnFindUsuarios();
  },
};
</script>
